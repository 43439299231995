import styled from 'styled-components';

const VideoWrapper = styled.div`
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  padding-top: 25px;
  height: 0;
  overflow: hidden;

  &:after {
    position: absolute;
    bottom: -4px; left: 0;
    width: 101%;
    height: 50%;
    content: '';
    background: linear-gradient(transparent, black);
    pointer-events: none;

    opacity: ${props => (props.showTitle ? 1 : 0)};
  }

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  h2 {
    position: absolute;
    bottom: 2rem;
    width: 90%;
    left: 5%;
    text-align: center;
    z-index: 1;
    color: #fff;
    margin-bottom: 0;
    font-weight: 400;
    font-size: 2rem;

    @media only screen and (max-width: 768px) {
      font-size: 1rem;
    }
  }
`;

export default VideoWrapper;
