import styled from 'styled-components';

const HomeWrapper = styled.div`
  .video-section-hero {
    position: absolute;
    top: 0; left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    filter: brightness(0.33);
  }
`;

export default HomeWrapper;
