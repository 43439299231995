/* eslint-disable max-len */
/* eslint indent: 0 */

import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import ReactCodeInput from 'react-code-input';
import styled from 'styled-components';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import HomeWrapper from '../components/Home/HomeWrapper';
import CodeWrapper from '../components/Home/CodeWrapper';
import Wrapper from '../components/shared/wrapper';
import Layout from '../components/shared/Layout';
import SEO from '../components/shared/seo';
import StyledSection from '../components/shared/section';
import InstructionWrapper from '../components/Home/InstructionWrapper';
import Player from '../components/shared/Player';
import ParallaxSection from '../components/shared/ParallaxSection';
import Articles from '../components/Home/Articles';
import Article from '../components/Home/Article';
import Footer from '../components/shared/Footer';
import JoinWrapperGuide from '../components/shared/JoinWrapperGuide';
import KeySubmit from '../components/Home/KeySubmit';

import VideoBG from '../images/videobg.jpeg';
import ArticleImg1 from '../images/img3new.jpg';
import ArticleImg2 from '../images/img4new.jpg';
import ArticleImg3 from '../images/articleimg3.jpeg';
import ArticleImg4 from '../images/articleimg4.jpeg';
import Arrow from '../images/arrow_scroll.png';

import EGLogo from '../images/logos/eg.png';
import VGLogo from '../images/logos/VG247.png';
import Logo from '../images/main_logo.png';
import PushLogo from '../images/logos/push.png';

const pinProps = {
  className: ReactCodeInput,
  inputStyle: {
    margin: '4px',
    width: 100,
    height: 100,
    textAlign: 'center',
    outlineColor: 'transparent',
    background: 'transparent',
    color: '#fff',
    border: '2px solid rgb(255, 114, 36)',
  },
};

const HeroSection = styled(StyledSection)`
  position: relative;
  padding: 5rem 0 6rem 0;
  overflow: hidden;
  background: #222;
  &:after {
    position: absolute;
    top: 0; left: 0;
    content: '';
    width: 100%;
    height: 100%;
    box-shadow: inset 0px -48px 40px 20px rgba(0,0,0,0.9);
    pointer-events: none;

    @media only screen and (max-width: 1316px) {
      display: none;
    }
  }
    
  @media only screen and (max-width: 1024px) {
    padding: 1rem 0;
    padding-bottom: 0;
    background: #000;
  }

  .main-bg {
    position: absolute !important;
    top: 0; left: 0;
    width: 100%;
    height: 100%;
    transform: scale(1.1);

    @media only screen and (max-width: 1024px) {
      display: none;
    }
  }
`;

class IndexPage extends Component {
  state = {
    pin: '',
    promptText: '',
    showSite: true,
  }

  onChange = (value) => {
    if (value.length === 6) {
      if (value === '954133') {
        this.setState({
          showSite: true,
        });
      } else {
        this.setState({
          promptText: 'INCORRECT',
        });
      }
    } else {
      this.setState({
        promptText: '',
      });
    }
  }

  render() {
    const { showSite, promptText, pin } = this.state;
    const { data } = this.props;
    return (
      <HomeWrapper>
        {showSite === false ? (
          // correct code is yet to be entered, display code input
          <Fragment>
            <CodeWrapper>
              <SEO page="Unlock" />
              <div className="inner-wrapper">
                <img className="logo" src={Logo} logo="" alt="" />
                <h1>
                  Enter The
                  {' '}
                  <span>Access Code</span>
                </h1>
                <ReactCodeInput fields={6} {...pinProps} onChange={this.onChange} value={pin} type="number" />
                <p>{promptText}</p>
                <h3>Gain Entry To Register For A</h3>
                <h2>Free Private Beta Key</h2>
              </div>
            </CodeWrapper>
            <Footer />
          </Fragment>
        ) : (
          <Layout>
            <SEO page="Home" />
            <HeroSection styles={{
              borderBottom: '2px solid  rgb(255, 114, 36)',
            }}
            >
              <Img className="main-bg" fluid={data.mainBG.childImageSharp.fluid} />
              <Wrapper>
                <JoinWrapperGuide team="Arekkz">
                  <KeySubmit
                    signupText="PLAY, UPLOAD, & WIN"
                    subText="SIGN UP NOW AND TAKE PART TODAY"
                    quizId={53}
                  />
                </JoinWrapperGuide>
              </Wrapper>
            </HeroSection>
            <StyledSection styles={{
              background: '#fff',
            }}
            >
              <Wrapper styles={{
                minHeight: 400,
                padding: '2rem 1rem',
              }}
              >
                <InstructionWrapper>
                  <img className="arrow" src={Arrow} alt="" />
                  <div className="copy">
                    <h2>Play Upload And Win</h2>
                    <p>WIN an Xbox One X or PS4 Pro and Dark Zone Collector’s Edition. Simply UPLOAD and SHARE your heroic moments to YouTube from inside the iconic Dark Zone as part of The Division 2 &apos;s Open Beta using #enterthedarkzone. </p>
                    <p>Protect or betray your fellow agents as you take on other players and test your combat proficiency in one of the three new Dark Zones scattered around Washington D.C. Trust and suvival skills will be key as you look to extract with your hard-earned loot intact. </p>
                    <p>See the above instructions on how to upload using your consoles share functionality. </p>
                    <p>Good luck, Agent! </p>
                  </div>
                  <div className="steps">
                    <div className="iframe-wrapper">
                      <iframe title=" " src="https://www.youtube.com/embed/wp8lqguvF8M" />
                    </div>
                  </div>
                </InstructionWrapper>
              </Wrapper>
            </StyledSection>
            <StyledSection
              styles={{
                padding: '6rem 0',
                background: '#222',
                borderTop: '2px solid rgb(255, 114, 36)',
                borderBottom: '2px solid rgb(255, 114, 36)',
                position: 'relative',
              }}
            >
              <img
                src={VideoBG}
                alt=""
                className="video-section-hero"
              />
              <Wrapper
                styles={{
                boxShadow: '0px 2px 4px 0px rgba(0,0,0,0.5)',
                }}
              >
                <Player videoId="GduBq6JEio4" title="Arekkz: Division 2 Beta Tips" />
              </Wrapper>
            </StyledSection>
            <ParallaxSection background={data.parallaxBG.childImageSharp.fluid}>
              <span><h2>History</h2></span>
              <h2>Will</h2>
              <span><h2>Remember</h2></span>
              <a href="http://ubi.li/zkqv5" target="_blank" rel="noopener noreferrer">
                <Img className="parallax-cta" fluid={data.parallaxButton.childImageSharp.fluid} />
              </a>
            </ParallaxSection>
            <StyledSection
              style={{
                background: '#111',
                borderTop: '2px solid  rgb(255, 114, 36)',
                borderBottom: '1px solid #fff',
              }}
            >
              <Wrapper
                style={{
                  padding: '8rem 0 12rem 0',
                  maxWidth: '1440px',
                }}
              >
                <h2 style={{
                  textAlign: 'center',
                  textTransform: 'uppercase',
                  margin: '0 auto',
                  color: '#fff',
                  fontWeight: '400',
                }}
                >
                  Our Editorial
                </h2>
                <Articles>
                  <Article title="The Division 2 is ringing in some big changes..." background={ArticleImg1} url="https://www.eurogamer.net/articles/2019-01-17-the-division-2-is-ringing-in-some-big-changes-for-the-dark-zone" logo={EGLogo} color="#2E84CB" />
                  <Article title="The Division 2: here’s an in-depth look..." background={ArticleImg2} url="https://www.vg247.com/2019/01/23/the-division-2-skills-categories/" logo={VGLogo} color="#C6252D" />
                  <Article title="The Division 2 Private Beta: Tips to take back D.C." background={ArticleImg3} url="https://www.vg247.com/2019/02/08/the-division-2-beta-tips/" logo={VGLogo} color="#C6252D" />
                  <Article title="A Group of Much Tougher Enemies Take Over..." background={ArticleImg4} url="http://www.pushsquare.com/news/2019/02/a_group_of_much_tougher_enemies_take_over_the_division_2_once_youve_beaten_the_campaign" logo={PushLogo} color="#1C50A8" />
                </Articles>
              </Wrapper>
            </StyledSection>
            <Footer />
          </Layout>
        )}
      </HomeWrapper>
    );
  }
}

const Home = ({ data }) => (
  <IndexPage data={data} />
);

Home.propTypes = {
  data: PropTypes.shape({}).isRequired,
};

IndexPage.propTypes = {
  data: PropTypes.shape({}).isRequired,
};


export default Home;

export const pageQuery = graphql`
  query {
    parallaxBG: file(relativePath: {eq: "quote_panel.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 2500) {
          src
          srcSet
          srcSetWebp
          sizes
          aspectRatio
        }
      }
    }
    parallaxButton: file(relativePath: {eq: "CTA.png"}) {
      childImageSharp {
        fluid(maxWidth: 439) {
          src
          srcSet
          srcSetWebp
          sizes
          aspectRatio
          tracedSVG
        }
      }
    }
    mainBG: file(relativePath: {eq: "keyart_bg.png"}) {
      childImageSharp {
        fluid(maxWidth: 2500) {
          src
          srcSet
          srcSetWebp
          sizes
          aspectRatio
        }
      }
    }
  }
`;
