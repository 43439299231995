/* eslint-disable jsx-a11y/anchor-has-content */
import React from 'react';
import PropTypes from 'prop-types';

import StyledArticle from './style';

const Article = ({
  title, background, url, logo, color,
}) => (
  <StyledArticle background={background} color={color}>
    <p>{title}</p>
    <img className="logo" src={logo} alt="" />
    <a href={url} target="_blank" rel="noopener noreferrer" />
  </StyledArticle>
);

Article.propTypes = {
  title: PropTypes.string.isRequired,
  background: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  logo: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
};

export default Article;
