import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Youtube from 'react-youtube';

import VideoWrapper from './style';

class Player extends Component {
  state = {
    showTitle: true,
  }

  hideTitle = () => {
    this.setState({ showTitle: false });
  }

  render() {
    const { showTitle } = this.state;
    const { videoId, opts, title } = this.props;
    return (
      <VideoWrapper className="player-wrapper" showTitle={showTitle}>
        <Youtube
          videoId={videoId}
          opts={opts}
          onPlay={this.hideTitle}
        />
        {showTitle
          && <h2>{title}</h2>
        }
      </VideoWrapper>
    );
  }
}

Player.propTypes = {
  videoId: PropTypes.string,
  title: PropTypes.string,
  opts: PropTypes.shape({}),
};

Player.defaultProps = {
  videoId: '',
  title: 'Enter The Dark Zone with The Division 2 and Outside Xbox',
  opts: {
    playerVars: {
      autoplay: 0,
      controls: 0,
      rel: 0,
      playsinline: 1,
    },
  },
};
// test
export default Player;
