import styled from 'styled-components';

const BaseEmailSubmit = styled.div`
  min-height: 450px;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 0 2rem;
  padding-top: 2rem;

  h4 {
    font-weight: 700 !important;
    padding: 0 1rem;
    margin-bottom: 0;
    color: #222 !important;
  }

  h5 {
    color: #F36B2E;
    padding: 0 2rem;
  }

  .disclaimer {
    font-size: 14px;
    text-align: center;
    padding: 0 2rem;
    margin-bottom: 1.5rem;
  }

  strong {
    font-weight: 700;
  }

  @media only screen and (max-width: 1024px) {
    padding-top: 2rem;
  }

  .email-submit-title {
    margin-bottom: 1rem;
    text-align: center;

    @media only screen and (max-width: 1024px) {
      margin-bottom: 1.5rem;
    }
  }

  .email-submit-form {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    label {
      width: 100%;
      max-width: 600px;
      display: flex;
      flex-direction: column;
      margin-bottom: 1.5rem;

      @media only screen and (max-width: 1024px) {
        margin-bottom: 1rem;
      }

      @media only screen and (max-width: 600px) {
        padding: 0;
      }

      input {
        flex-grow: 1;
        height: 2.25rem;
        box-shadow: 0px 1px 4px 1px rgba(0,0,0,0.15);
        padding-left: 0.5rem;
        border: none;
        border: 1px solid rgba(0,0,0,0.1);
      }

      span {
        margin-bottom: 0.5rem;
      }
    }

    .terms-wrapper {
      display: block;
      text-align: left;
      user-select: none;
      cursor: pointer;
      margin-bottom: 1.5rem;
      font-size: 14px;

      span {
        display: block;
      }

      a {
        color: rgba(0, 100, 255, 0.7);
      }

      input {
        vertical-align: middle;
        margin-left: 0.25rem;
        margin-bottom: 4px;
        border: none;
        box-shadow: none;
      }
    }

    .submit-btn {
      width: 100%;
      height: 40px;
      margin-bottom: 1rem;
      text-transform: uppercase;
      cursor: pointer;
      border: none;
      color: #fff;
      font-size: 16px;
      background-image: linear-gradient(90deg, #ff7224, #c34400);

      &:hover {
        background-image: linear-gradient(180deg, #ff7224, #c34400);
      }

      @media only screen and (max-width: 1024px) {
        margin-bottom: 2.5rem;
      }
    }

    .success-text {
      text-align: center;
      margin-top: 1.5rem;
      font-weight: 400;
    }
  }

  .email-submit-success-wrapper {

  }
`;

export default BaseEmailSubmit;
