import styled from 'styled-components';
import BG from '../../images/homebg.jpg';
import Top from '../../images/top.png';

const CodeWrapper = styled.div`
  position: relative;
  top: 0; left: 0;
  width: 100%;
  min-height: 100vh;
  background: #111;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-image: url(${BG});
  background-size: cover;
  background-position: center center;
  font-family: 'Exo 2', sans-serif;
  padding-top: 4rem;
  padding-bottom: 2rem;

  input[type="number"]::-webkit-outer-spin-button, input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
 
  input[type="number"] {
    -moz-appearance: textfield;
  }

  p {
    margin-top: 2rem;
  }

  input {
    position: relative;
    background: #111 !important;
    z-index: 1;
    @media only screen and (max-width: 768px) {
      width: 40px !important;
      height: 40px !important;
    }
  }

  .inner-wrapper {
    position: relative;
    width: 1280px;
    max-width: 100%;
    background: rgba(17, 17, 17, 0.87);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    /* min-height: 70vh; */
    border: 1px solid #F57225;
    padding: 2rem 0;
    padding-top: 150px;

    @media only screen and (max-width: 768px) {
      padding-top: 50px;
    }

    &:before {
      position: absolute;
      top: 0; 
      left: 0;
      content: '';
      width: 100%;
      height: 100%;
      background-image: url(${Top});
      background-repeat: no-repeat;
      background-position: center -2px;
      pointer-events: none;
    }

    &:after {
      position: absolute;
      bottom: 0; 
      left: 0;
      content: '';
      width: 100%;
      height: 100%;
      background-image: url(${Top});
      background-repeat: no-repeat;
      background-position: center -2px;
      pointer-events: none;
      z-index: 0;
      transform: rotate(180deg);
      opacity: 0.33;
    }

    h1 {
      font-size: 4rem;
      text-transform: uppercase;
      margin-bottom: 2rem;
      border-bottom: 2px dotted #F57225;
      padding-bottom: 1rem;
      text-align: center;
      font-weight: 700;
      z-index: 1;

      span {
        color: #F57225;
      }

      @media only screen and (max-width: 1024px) {
        font-size: 2rem;
      }

      @media only screen and (max-width: 600px) {
        font-size: 1.5rem;
      }
    }

    h3 {
      color: #F57225;
      margin-top: 1rem;
      text-transform: uppercase;
      font-size: 2rem;
      margin-bottom: 0.5rem;
      text-align: center;
      @media only screen and (max-width: 1024px) {
        font-size: 1.25rem;
      }
    }

    h2 {
      font-size: 3rem;
      max-width: 340px;
      text-align: center;
      margin-top: 0.5rem;
      text-transform: uppercase;
      @media only screen and (max-width: 1024px) {
        font-size: 1.5rem;
      }
    }

    .logo {
      position: absolute;
      transform: translateY(-50px);
      width: 480px;
      top: 0;

      @media only screen and (max-width: 768px) {
        width: 220px;
      }
    }
  }
`;

export default CodeWrapper;
