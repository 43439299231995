import styled from 'styled-components';

const Articles = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  min-height: 300px;
  margin-top: 3rem;
  padding: 0 1rem;

  @media only screen and (max-width: 1024px) {
    margin-bottom: 1rem;
  }
`;

export default Articles;
