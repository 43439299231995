import styled from 'styled-components';

const StyledArticle = styled.div`
  position: relative;
  width: calc(25% - 2rem);
  height: 300px;
  background: #111;
  border: 2px solid #ff7224;
  background-image: url(${props => props.background});
  background-size: cover;
  background-position: center center;
  box-shadow: 2px 4px 8px 0px rgba(0,0,0,0.33);
  --site-color: ${props => props.color};

  &:after {
    position: absolute;
    bottom: 0; left: 0;
    width: 100%;
    height: 30%;
    content: '';
    opacity: 0.75;
    background-image: linear-gradient(0deg, var(--site-color), transparent);
    filter: brightness(0.75);
  }

  p {
    position: absolute;
    bottom: 0rem;
    left: 1rem;
    color: #fff;
    line-height: 1;
    font-size: 1.5rem;
    z-index: 1;
    text-shadow: 1px 1px 4px rgba(0,0,0,0.5);
  }

  a {
    position: absolute;
    top: 0; left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }

  .logo {
    position: absolute;
    width: 100px;
    z-index: 1;
    margin-bottom: 0;
    top: -30px;
    left: 1rem;
    height: auto;
  }

  @media only screen and (max-width: 1024px) {
    width: 100%;
    height: 250px;
    margin-bottom: 2rem;
  }
`;

export default StyledArticle;
