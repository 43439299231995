import styled from 'styled-components';

const InstructionWrapper = styled.div`
  position: relative;
  display: flex;
  padding: 7rem 0 4rem 0;
  flex-wrap: wrap;

  @media only screen and (max-width: 1024px) {
    padding: 1rem 0;
  }

  h2 {
    margin-bottom: 2rem;
    text-transform: uppercase;
    margin-top: 0;
    color: rgb(255, 114, 36);
  }

  .copy {
    width: 50%;

    @media only screen and (max-width: 1024px) {
      width: 100%;
      border-right: 0;
      margin-bottom: 2rem;
    }

    p {
      max-width: 55ch;
      margin-bottom: 1.5rem;

      @media only screen and (max-width: 1024px) {
        max-width: none;
      }
    }
  }
  .steps {
    width: 50%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    flex-direction: row;

    .iframe-wrapper {
      position: relative;
      width: 100%;
      height: 0;
      padding-bottom: 56.25%;
      overflow: hidden;
      margin-left: auto;

      iframe {
        position: absolute;
        top: 0; left: 0;
        width: 100%;
        height: 100%;
      }
    }

    @media only screen and (max-width: 1024px) {
      width: 100%;
      align-items: flex-start;
    }
  }
  .step {
    width: 80%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    /* min-height: 100px; */
    border-bottom: 1px solid rgba(0,0,0,0.1);
    margin-bottom: 2rem;
    padding-bottom: 2rem;

    h3, p {
      margin: 0;
    }

    p {
      max-width: 340px;
      
    }

    h3 {
      text-transform: uppercase;
      margin-right: 2rem;
      color: rgb(255, 114, 36);
      font-weight: 400;
      
      @media only screen and (max-width: 700px) {
        margin-right: 0rem;
      }
    }

    @media only screen and (max-width: 1024px) {
      width: 100%;
      flex-direction: column;

      h3 {
        margin-bottom: 0.5rem;
      }
    }
  }
  .arrow {
    position: absolute;
    top: -100px;
    width: 300px;
    left: calc(50% - 150px);
    z-index: 2;

    @media only screen and (max-width: 1024px) {
      display: none;
    }
  }
`;

export default InstructionWrapper;
